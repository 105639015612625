import React, { useState, useEffect, useRef, useLayoutEffect} from "react";
import Amplify, { API } from "aws-amplify";
import "./Viewer.css";
import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl,Button, Row, Col } from 'react-bootstrap'
import Axios from "axios";
import  "./LargeSpinner.css";
import ViewerCanvas from "./ViewerCanvas.js"
//import Zlib from "react-zlib-js";
import Pako from "pako";
import AWS from "aws-sdk";
import aws_exports from "../aws-exports";
import Slider, { Range } from 'rc-slider';


import ThroughputChart from "../Components/Charts/ThroughputChart";
//import { ScrollableCanvas } from "react-scrollable-canvas";

import "./rc-slider_index.css"

Amplify.configure(aws_exports, {});

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  });




var sclines = null;

const ChannelColors = [
  "#dcbeff",
  "#aaffc3",
  "#ffd8b1",
  "#fabed4",
  "#fffac8"
]



var cntDbg = 0;

async function getBlcFileWithAxios (signedurl){
    const url = signedurl;

    
      const response = await Axios({
        url,
        method: 'GET',
        responseType: 'arraybuffer',
       
        headers: {
          'Content-Type':"application/json",
          'Accept': '*/*'
      
        }
        
      })
  

let buffer = response.data;
      
let view = new Uint8Array(response.data); // treat buffer as a sequence of 16-bit integers
let unzip = null;
      try{
       unzip = Pako.ungzip(view);
      }catch (er){
        throw ("Error uncompressing BLC file: "+er)
      }


       if (unzip == null || unzip == undefined )
         throw ("Couldn't uncompress BLC file!")


      try{


let view16 =  new Uint16Array(unzip.buffer, unzip.byteOffset, unzip.byteLength / Uint16Array.BYTES_PER_ELEMENT);




      let minY = view16[0];
      let ySpan = view16[1] - minY;
      //Count the scan lines
      let len = view16.length;
      let i = 2; //Starts after the header
      let cnt=0;
      let n=0;
      let sclines = [];
      while (i<len){
        try{
        let n= view16[i];

        if (n==0){
          sclines.push([]) 
          i++;
        }else{
         
          let oneLine = [];
          i++;
          for (let j=0; j<2*n; j++){
            oneLine.push(view16[i++]-minY)
          }
  
         // console.log (oneLine);
          sclines.push(oneLine);

        }
        //i+= 1+2*view16[i];
       
        cnt ++;
      }catch (err){
        console.log ("Exception parsing data. Idx:"+i+",  length:"+len);
        throw ("Error processing data (BLC)")
      }

      }

   
      return {
          ySpan : ySpan,
          lines : sclines
      }

    }catch (err){


      console.log("error in axio call: "+err);
      if (err.toString().indexOf ("404") > 0)
        throw ("Datafile (BLC) not found!")
      else
        throw ("Error fetching or processing data file (BLC): "+err);
    }
    return null;
}





















export default function Viewer(props) {



  const wrapRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [scanLines, setScanLines] = useState ([])

    const [cwidth, setWidth] = useState(0);
    const [cheight, setHeight] = useState(0);

    const [TheData, setTheData] = useState (null);

    const [Position, setPosition] = useState (0);
    const [TheError, setTheError] = useState("");
const [TheCount, setTheCount]= useState(null);
const [CountFileError, setCountFileError]=useState ("");



        useEffect( () => {


          function handleResize() {

            setWidth (wrapRef?.current?.offsetWidth)
            setHeight (wrapRef?.current?.offsetHeight)
          
      }
      
          window.addEventListener('resize', handleResize)


          // let S3 = nwe AWS.S3();



          if (!props.userData)
            fetchWithFetch();
          else   
            fetchWithAPI();
/*
let apiName = "vakicloudRdsAPI";
let path = "/getFile/Signed";
let myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {
    // OPTIONAL
    PathBlc: props.Path
  }
};




API.get(apiName, path, myInit).then(response => {
 
    loadScanlines(response.urlBlc, response.urlCnt);

    getCntFileWithAxios(response.urlCnt, props.NrChannels);

}).catch (err => {console.log ("Error fetching signed: "+err)});

*/

return () => {
  window.removeEventListener('resize', handleResize)

}

    
   }, []);


const fetchWithAPI = () =>
{


  let apiName = "vakicloudRdsAPI";
  let path = "/getFile/Signed";
  let myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
      PathBlc: props.Path
    }
  };
  
  
  
  
  API.get(apiName, path, myInit).then(response => {
   

      loadScanlines(response.urlBlc, response.urlCnt);
      getCntFileWithAxios(response.urlCnt, props.NrChannels);
  
  }).catch (err => {console.log ("Error fetching signed: "+err)});


}


const fetchWithFetch = async () =>
{



  let theUrl = 'https://i84r4clg2d.execute-api.eu-west-1.amazonaws.com/dev/counts/Signed?PathBlc='+props.Path;


 await fetch(theUrl , { 
      method: 'get', 
     // mode: 'no-cors',
      headers: new Headers({
        //  'authorizationToken': "'"+theGuid + "'"

        //  'Content-Type': 'application/x-www-form-urlencoded'
      }) 
    //  body: 'A=1&B=2'
  }).then((res) => {

         

        
         //results = res.json();
         return res.json();
         //return JSON.stringify(res);
     })
    .then((resJson) => {
  
      loadScanlines(resJson.urlBlc, resJson.urlCnt);
  
      getCntFileWithAxios(resJson.urlCnt, props.NrChannels);




       //  callback( resJson);
     }).catch ((er)=>{
      //callback (er);
      console.log ("Catch: "+er);
     })





}





   const getCntFileWithAxios = (signedurl, NrChannels)=>{
    const url = signedurl;
    var dbg = 0;
  
    try{
      
      Axios({
        url,
        method: 'GET',
        responseType: 'arraybuffer',
       
        headers: {
          'Content-Type':"application/json",
          'Accept': '*/*'
      
        }
        
      }).then ((res) => {
    
        let view = new Uint32Array(res.data); 
       
        //the received data might have been 64 or 32 bit array.   
        let Num = 2; //2 ints entries -> 32 bit array
        if (view[1] == 0)
          Num = 4;  
        let len = view.length/Num;
        let view2 = [];
  




        if (!NrChannels)
          NrChannels = 1;
  
        for (let i=0; i<NrChannels; i++){
          view2.push([]);
        }
 

        let idx = 0;
        try{
          for (let i=0; i<len ; i++){
           dbg = i;
          
            if (NrChannels > 1){ //ignore possible channel marking in blb if numchannels is 1
                idx = 255&(view[Num*i+Num/2]-1);  //Channel number stored in first 8 bits.  Y-position might be in the upper bytes - which is ignored here
            }
            
            view2[idx].push(view[Num*i]);
          }
        }catch (err){
          console.log ("Error processing getCntFile: "+err );
          setTheCount (null)
          setCountFileError ("("+idx+") Count file incorrect or corrupted!")
       

        }





      
        let tmpCnt = {};
        for (let i=0; i<NrChannels; i++){
          tmpCnt["c"+i] = view2[i];
          
        }


      
        setTheCount (tmpCnt);


      } )
      .catch ((er) => {
        
        if (er.toString().indexOf ("404") > 0){
          setCountFileError ("Count file missing!")
        }else{
          setCountFileError ("Error fetching count file!")
        }
        setTheCount(null);
       
      })
  
  
    }catch (err){
    
      setCountFileError ("Count file error!")
      setTheCount(null);
      //throw (err);
    }
    
    
  }
  






 const drawChannels = (xwidth, mult, ctx, counts) => {
/*
    ori["ChannelName"] = [];
    ori["ChannelCounts"] = [];
    ori["SizeGroups"] = [];
    ori["Multie"] = [];
*/

    let baseY = props.Ori.Multie[0];
    let num = props.Ori.ChannelName.length;
    if (num <= 1)
      return;





    let ch = 0;

   // ctx.font = "30px Roboto Bold";
   ctx.font = "1em Arial Bold";
    ctx.globalCompositeOperation = 'xor';
  
    ctx.strokeStyle = 'silver';
    for (let i = 2; i<(2*num); i+=2){
      let y = (props.Ori.Multie[i] - baseY)*mult;
      ctx.beginPath();
      ctx.moveTo(0, y);
      ctx.lineTo(xwidth, y);
      
      ctx.stroke();
      ctx.fillStyle = ChannelColors[ch];
      ctx.font = "1em Arial Bold";
      ctx.fillText(props.Ori.ChannelName[ch], 5, y-7*mult);
      let x = props.Ori.ChannelName[ch].length*10+5; 
      ctx.font = "2em Arial Bold";
      if (counts[ch] != undefined)
        ctx.fillText(counts[ch], x, y-7*mult)

      ch++;

    }


   
    let y = (props.Ori.Multie[2*num-1] - baseY)*mult;
    ctx.fillStyle = ChannelColors[ch];
    ctx.font = "1em Arial Bold";
    ctx.fillText(props.Ori.ChannelName[ch], 5, y-9*mult);

    let x = props.Ori.ChannelName[ch].length*10+5; 
    ctx.font = "2em Arial Bold";
    if (counts[ch] != undefined)
      ctx.fillText(counts[ch], x, y-9*mult)


 }  

const draw = (ctx, pos, counts) => {


  let position =  Math.round(pos);
 
  let scanLines = TheData.lines;
  let ySpan = TheData.ySpan;
  let  numScans = scanLines.length; 

  let m= cheight / ySpan;
  let maxIdx = position+cwidth/m;


  let di1 = 1/m;
  let di2 = Math.floor (di1);
  if (di2 != di1)
    di1++;

 let colors = ["silver", "yellow", "blue", "green", "purple", "magenta", "red"]

let numColors = colors.length;

 let cntLogs = 0;



let nextCnts = [];
for (let k = 0; k<counts.length; k++){
    let cIdx = "c"+k;
nextCnts.push (-1);
   let len = TheCount[cIdx].length;
   if (pos < TheCount[cIdx][0]){
      nextCnts[k]=0;
   }else {
    for (let j = 0; j<len; j++){
      if (TheCount[cIdx][j] >= pos){
        nextCnts[k] = j;
        break;
      }
    }

   }
        
 

}




let baseY = props.Ori.Multie[0];
//Get next count pos


  ctx.clearRect(0,0,cwidth, cheight)

  let scrIdx = 0;
  for (let i = position; i<maxIdx && i<numScans; i++ ){
      let line = scanLines[i];
      //ctx.beginPath();
      //ctx.lineWidth = 1;
      //ctx.strokeStyle = 'white';
      //ctx.moveTo (scrIdx,0);
      if (line == [] || !line ){
        ;
        //ctx.lineTo(scrIdx, cheight);
        //ctx.stroke();

      }else {
        let num = line.length;
    
        for (let j=0; j<num/2; j++){
         // ctx.lineTo(scrIdx, line [2*j]*m);
          //ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(scrIdx*m, line [2*j]*m );
          ctx.lineTo(scrIdx*m, line[2*j+1]*m);
          ctx.strokeStyle = '#808B96';
          ctx.stroke();
         // ctx.beginPath();
         // ctx.moveTo(scrIdx, line [2*j+1] *m);
         // ctx.strokeStyle = 'white';
        }
        //ctx.lineTo(scrIdx, cheight);
        //ctx.stroke();


      }

/*   Endurskoða með nýju formati á cnt skrám


      for (let k = 0; k<counts.length; k++){

        let cntFish = 0;
        let cIdx = "c"+k;
        while ( nextCnts[k] < numScans &&  TheCount[cIdx] [nextCnts[k]] ==i)   ){

          let colIdx = cntFish;
          if (colIdx >= numColors)
            colIdx = numColors - 1;
          cntFish ++;
          if (cntLogs < 50 && k==0){
          console.log (k+"     Got one at: "+ i+ "   withMult: "+i*m)
          console.log (i, "  Scan: "+ JSON.stringify (scanLines[i]) )
          console.log (i-1, "  Scan: "+ JSON.stringify (scanLines[i-1]))
          cntLogs++;
          }

/////////////////////








ctx.globalCompositeOperation = "source-over";
ctx.strokeStyle = colors[colIdx];
ctx.lineWidth = cntFish;
  let y2 = (props.Ori.Multie[2*(k+1)-1] - baseY)*m;
  let y1 = (props.Ori.Multie[2*(k+1)-2] - baseY)*m;
  ctx.beginPath();
  ctx.moveTo(scrIdx*m, y1);
  ctx.lineTo(scrIdx*m, y2);
  //ctx.strokeStyle =    '#808B96';
  ctx.stroke();


          nextCnts[k]++;
          if (nextCnts[k] >= TheCount[cIdx].length )
            nextCnts[k] = numScans+23;
            
       
    }
    if (cntFish > 1){
      console.log (k, " CountInScan: "+cntFish);
    }
  }
      */

      scrIdx ++;
  }

  drawChannels(cwidth, m, ctx, counts)


}







const loadScanlines = async (urlBlc, urlCnt) => {
  
  
  setLoading(true);

   // let S3 = nwe AWS.S3();
   try{

   



    

  let res = await getBlcFileWithAxios(urlBlc);




  setTheData (res);
    
   }catch(err){
      console.log ("Exception::::: "+err)
        
    setTheError (err);
    setLoading(false);
   

   }
    
   setLoading(false);

   

 
}



useLayoutEffect(() => {



 setWidth(wrapRef?.current?.offsetWidth);
 setHeight(wrapRef?.current?.offsetHeight);


}, []);


const  getDurationString = (days) => {
  if (isNaN( days) )
      return "";
  let h = Math.floor (days*24);
  let m = Math.floor( days*24*60 - h*60);
  let s = Math.round(days*24*60*60 - h*60*60 - m*60 );

  return <p> <span className="siz-h"> { h+"h "}</span>  <span className="siz-m" >{m+"m "} </span> <span className="siz-s"> {s+"s"} </span> </p>
  
  





}


const doCount = (arr,pos) => {



  if (!pos)
    return 0;

  if (!arr ||arr.length == 0)
    return 0;
    


  let tot = arr.length;
/*
    L := 0
    R := n
    while L < R:
        m := floor((L + R) / 2)
        if A[m] > T:
            R := m
        else:
            L := m + 1
    return R - 1

*/

  let L = 0;
  let R = tot-1;
let cnt =0;
  while (L<R){
    let m= Math.floor((L+R)/2);
    if (arr[m] > pos){
      R=m;
    }else{  
      L=m+1;
    } 
    cnt++;
  }



  return R;








}


const sliderOnAfterChange = value => {
  setPosition (value);
};


let multi=1;

if (TheData && TheData.ySpan > 0)
    multi= cheight / TheData.ySpan;

let strCount = "Timeline count data not available";
if (CountFileError != "")
  strCount = CountFileError;


  let instCount = {left: [],
                   screen: [],
                  right: [] }


  if (TheCount != null ){
    let totLeft = 0;
    let totScreen = 0;
    let totRight = 0;
    
    for (let i = 0; i< props.NrChannels; i++){
      instCount.left.push ( doCount (TheCount["c"+i],Position));
      instCount.screen.push(doCount (TheCount["c"+i], Position+cwidth/multi) - instCount.left[i])
      instCount.right.push (TheCount["c"+i].length- instCount.left[i] - instCount.screen[i])

      if (cntDbg < 20){

        cntDbg ++;
      }
      totLeft += instCount.left[i];
      totScreen += instCount.screen[i];
      totRight += instCount.right[i];
    }


    strCount = totLeft+" <-|-> "+totScreen+" <-|-> "+totRight;
    
  }



let ViewArea = "";


if (TheError  != ""){
 

   ViewArea = <div  id = "error"> {TheError.toString()} </div>

}else if (loading || !TheData || cheight==0){



  ViewArea = <div id="spinnerwrap">  <div className="spinner-wrap">  <div className="loader"></div> </div></div>
}else {
  
 ViewArea = 
//<ViewerCanvas  Data={TheData.lines}   Yspan={TheData.ySpan} CanvasWidth={cwidth}  CanvasHeight = {cheight} />
<ViewerCanvas draw={draw} width={cwidth+"px"}  Counts = {instCount.screen} height={cheight+"px"}  Position={Position}/>
}



let maxValue = 100;
let yPixRatio = 1;
if (TheData && TheData.lines){
  maxValue = TheData.lines.length-cwidth;
  yPixRatio = cheight/TheData.ySpan;
}

let ystring = "pix/res ratio: "+yPixRatio.toFixed(3);
  


 let strPosition = getDurationString (Position/props.ScanRate/(60*60*24)) 

  



return (
<div className="wrap">



<div id="ThroughputDiv">

<ThroughputChart

dataPoints={props.dataPoints}
seriesColors = {ChannelColors}
channelNames={props.Ori.ChannelName}
Minimalized = {true}
ChartHeight= {100}

/> 



</div> 
<div id="SliderDiv">
<Slider
  onAfterChange={sliderOnAfterChange}  
  min = {0}
  max = {maxValue}
  step = {cwidth/multi/1.2}
  

/>

</div>

<div ref = {wrapRef} className="canvas-wrap">
{ViewArea}
</div>

<div>
<Row>
  <Col>
<div id="currPos"> {strPosition} </div>
</Col>
<Col>
<div id="count">{strCount} </div>
</Col>
<Col>
<div id="yratio"> {ystring} </div>

</Col>
</Row>
</div>



</div>




);


}