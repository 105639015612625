import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./LicenceGeneratorNew.css";
import { FieldGroup } from "../Components/Forms";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'


import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../Components/Loading";
import useFetch from "./useFetch";
import { listLicences } from "../graphql/queries";
import { createLicence } from "../graphql/mutations";
import aws_exports from "../aws-exports";
import { Discovery } from "aws-sdk";

import CounterTypeSelect  from "./CounterTypeSelect.js";

import Tables from "./Table";

Amplify.configure(aws_exports, {});









   let eventKey = null; 
export default function LicenceGeneratorNew(props) {
   const licenceList = useFetch(listLicences, null, {
      listLicences: { items: [] }
    });
    const [TypeList, setTypeList] = useState([]);

const [Num1, setNum1] = useState (0);
const [Num2, setNum2] = useState (0);
const [Num3, setNum3] = useState (0);
const [Num4, setNum4] = useState (0);

const [Counter1, setCounter1] = useState (-1);
const [Counter2, setCounter2] = useState (-1);
const [Counter3, setCounter3] = useState (-1);
const [Counter4, setCounter4] = useState (-1);


const [CameraNumber, setCameraNumber] = useState (0);
const [PcId, setPcId] = useState (0);
const [ProgramVer, setProgramVer] = useState (0);



const [VakiCloud, setVakiCloud] = useState (false);
const [PLC, setPLC] = useState (false);


const [TheCode, setTheCode] = useState ("");

const [ clearFrom, setClearFrom] = useState(-1);

const [Freeze, setFreeze] = useState (false);


const[ TheValids, setTheValids] = useState([false, false, false, false]);

const [Comment, setComment]=useState ("")




 useEffect(() => {


   
  loadData();


}, []);



async function saveLicence(licenceKey) {


   
   let counters = "@"+Num1+": " +TypeList[Counter1].Counter_Name +" Ch:"+TypeList[Counter1].Channel_Count +" ("+Counter1+")";
   let n = 1;
   if (TheValids[1]){
      counters += ";@"+Num2+": " +TypeList[Counter2].Counter_Name+ " Ch:"+TypeList[Counter2].Channel_Count+ " ("+Counter2+")";
      if (TheValids[2]){
         counters+=";@"+Num3+": "+TypeList[Counter3].Counter_Name+ " Ch:"+TypeList[Counter3].Channel_Count + " ("+Counter3+")";
         if (TheValids[3]){
            counters+=";@"+Num4+": "+TypeList[Counter4].Counter_Name +" Ch:"+TypeList[Counter4].Channel_Count + " ("+Counter4+")";
         } 
      }
   }
  

  

   let event = new Date();
   let newDate = moment(event).format("YYYY-MM-DD HH:mm");
  


   



   let licenceInfo = {
     id: licenceKey,
     licence: licenceKey,
     createdBy: props.userData.name,
     counterID: " ", //counterID.value,
     createdDate: newDate,
     expireDate:  "" ,
     magicNumber: PcId.toString(),
     proVersion: ProgramVer.toString(),
     cameraSerialNumber: CameraNumber.toString(),
     counterType: 0,
     productType: 0,
     comment: Comment,
     CounterDef: counters

   };
 

   await API.graphql(graphqlOperation(createLicence, { input: licenceInfo }));
   await licenceList.fetchData(listLicences);
 }

 function handleRowSelect(obj) {
   // Skip for now
 }


const loadData = async () => {

        //We need an ID for new Population
        
        let apiName = "vakicloudRdsAPI";
        let path = "/counters/getcountertypes";
        
        // https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters
        //Get ID for pop
        let myInit = {
        
        response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
          queryStringParameters: {
          
          }  
        
        };
   
        
        API.get(apiName, path, myInit)
        .then ((response) => {
        
           let len = response.length;
           for (let i = 0; i<len; i++)
            response.message["key"]=i; 
         
            setTypeList (response.message);
         // TrgPop.PopId = newPopId+1;

       
        
        return;
        
        })
        .catch ((er) => {
        console.log ("Catch AFTER get types "+JSON.stringify(er));
        
        alert ("Error fetching types");
        return;
        
        });

        return 
}




async function fetchLicence(
   Counter1, Counter2,Counter3,Counter4,
   Num1, Num2, Num3, Num4,
   ProgramVer, PcId, CameraNumber,
   PLC, VakiCloud

 ) {

   checkBadOnes();
   
   
   let ver = 1;
   //console.log("camserial",camserial, "magic",magic, "pversion",pversion, "duration",duration, "countertype",countertype, "extra",extra);
   var params ='Version='+ver+'&Counter1='+Counter1+'&Counter2='+Counter2+'&Counter3='+Counter3+'&Counter4='+Counter4
          +'&Num1='+Num1+'&Num2='+Num2+'&Num3='+Num3+'&Num4='+Num4
          +'&ProgramVer='+ProgramVer+'&PcId='+PcId+'&CameraNumber='+CameraNumber
          +'&PLC='+PLC+'&VakiCloud='+VakiCloud;

    


   await fetch(
     "https://kopxca6gw6.execute-api.eu-west-1.amazonaws.com/dev/generateLicene?" +
       params,
     {
       method: "POST"
     }
   )
     .then(response => response.json())
     .then(data => {
  
       setTheCode(data);
       setFreeze(true);
       /*
       setLicenceKey(data);
*/
       saveLicence(data);
       
     })
     .catch(error => {
       console.error("Error:", error);
       setTheCode ("Error: "+error);
     });
   //saveLicence(lKey);
 }




const checkBadOnes = () => {
   /*
   let FirstBad = 1;
   for(let i = 0; i<TheValids.length; i++){
      if (!TheValids[i]){
         setClearFrom(i);
         break;
      }
   }
  */

/*
   if (which == "num" ){
      if (val==0){
         if (idx == 1 && Counter1 == "-1"){
            setClearFrom (2);
         }else  if (idx == 2 && Counter2 == "-1"){
            setClearFrom (3);
         }else  if (idx == 3 && Counter3 == "-1"){
            setClearFrom (4);
         }
      }
   }else if (which == "cnt" ){
      if (val==-1){
         if (idx == 1 && Num1 == 0){
            setClearFrom (2);
         }else  if (idx == 2 && Num2 == 0){
            setClearFrom (3);
         }else  if (idx == 3 && Num3 == 0){
            setClearFrom (4);
         }
      }
   }
  */ 
}

/*
if (clearFrom >= 0){

   if (clearFrom <= 4){
      setNum4(0);
      setCounter4 (-1);
   }
   if (clearFrom <= 3){
      setNum3(0);
      setCounter3(-1);
   }
   if (clearFrom == 2){
      setNum2(0);
      setCounter2(-1); 
   }
   setClearFrom (-1);
}
*/



const onReset = () => {



   setFreeze(false);
   setNum1(0);
   setNum2(0);
   setNum3(0);
   setNum4(0);
 
   
   setCounter1(-1);
   setCounter2(-1);
   setCounter3 (-1);
   setCounter4 (-1);
   
   
   setCameraNumber (0);
   setPcId (0);
   setProgramVer (0);
   
   setVakiCloud (false);
   setPLC(false);
   
   
   setTheCode("");
   
   setComment("");
  
   
   
   
  setTheValids([false, false, false, false]);








 }



const toInt = (val) => {
   if (val.trim == "")
      return 0;

   return parseInt(val);   
}

const copyLink = () => {

   navigator.clipboard.writeText(TheCode);
   }

const checkValids = () => {

   
   let tmpValids = [];
   for (let i = 0; i<TheValids.length; i++)
      tmpValids.push (TheValids[i])

   let valid = true;

   for (let i = 0; i<TheValids.length; i++){
      let valid = true;
      
      if (i>0){
         for (let j=0; j<i; j++){
            if (!tmpValids[j]){
               valid = false;
               break;
            }
         }
      }

  

   
 
   if (valid && i == 0){
      if (Num1 == 0 || Counter1 == -1)
         valid = false;
      if (Num1 == Num2 || Num1 == Num3 || Num1 == Num4 )
         valid = false;
   }else if (valid && i == 1){
      if (Num2 == 0 || Counter2 == -1)
         valid =  false;
      if (Num2 == Num1 || Num2 == Num3 || Num2 == Num4 )
         valid =  false;
   }else if (valid && i == 2){
      if (Num3 == 0 || Counter3 == -1)
         valid =  false;
      if (Num3 == Num1 || Num3 == Num2 || Num3 == Num4 )
         valid =  false;          
   }else if (valid && i == 3){
      if (Num4 == 0 || Counter4 == -1)
         valid =  false;
      if (Num4 == Num1 || Num4 == Num2 || Num4 == Num3 )
         valid = false;    
   }

   
   tmpValids [i] = valid;



}

for (let i = 0; i<TheValids.length; i++){
   if (tmpValids[i] != TheValids[i]){
      setTheValids(tmpValids);
      return;
   }
}

   
   
}

let GenDisabled = true;


let InvalidStart = 0;


while (InvalidStart < 4 && TheValids[InvalidStart]){
   InvalidStart++;

}


if (InvalidStart > 0){   
/*
      if (InvalidStart <= 1){
         if (Counter2 >-1)  setCounter2 (-1);
         if (Num2 > 0) setNum2(0);
      }
      if (InvalidStart <= 2){
         if (Counter3 >-1)  setCounter3 (-1);
         if (Num3 > 0) setNum3(0);
      }
      if (InvalidStart <= 3){
         if (Counter4 >-1)  setCounter4 (-1);
         if (Num4 > 0) setNum4(0);
      }
 */
      GenDisabled = false;

}


if (!GenDisabled){
   GenDisabled = CameraNumber == 0;
}
if (!GenDisabled){
   GenDisabled =  PcId == 0;

}
if (!GenDisabled){
   GenDisabled =  ProgramVer == 0;

}


checkValids();

let TheLink = "";

if (TheCode != null && TheCode != "" ){
 
    TheLink = <div className="shareLink">
      
                <div>{TheCode}<span onClick={copyLink}  className="copyLink">
                <i className="fa fa-copy fa-regular" title="Copy link"></i></span>
                </div>
            </div>;
      //Count Valids


}

let TheMsg = "";
if (TheCode.length > 0 ){
   let n=0;
for (let i = 0; i<TheValids.length; i++){
   if (TheValids[i])
      n++;
}
if (n == 1)
   TheMsg = "Licence code for a single counter has been created";
else if (n>1)
   TheMsg="Licence code for a "+n+" counters combination has been created";
}

let TheCheckboxes =  "";


/*
<div id="CheckBoxes">


   <label>
 VakiCloud 
 
 <input
           name="VakiCloud"
              type="checkbox"
            
             checked = {VakiCloud}
              
             className = "TheCheck"
            //  value={VakiCloud}
              //validateion={null}
               onChange={(e) => setVakiCloud (e.target.checked)}
               disabled = {Freeze}
            />
            
            </label>

<div id="PLCcheck" >
            <label>
 PLC
 
 <input
           name="PLC"
              type="checkbox"
            
             checked = {PLC}
              
             className = "TheCheck"
            //  value={VakiCloud}
              //validateion={null}
               onChange={(e) => setPLC (e.target.checked)}
               disabled = {Freeze}
            />
            
            </label>
            </div>
            </div>

*/




 return <div>
<Row>
   <Col md={3}>
<div  id = "TypeSelectsDiv">
   <div className="HeaderText">
   Enter counter type(s) and number(s) from top
  </div>
<CounterTypeSelect Types = {TypeList} Valid = {TheValids[0]} TheValue = {Counter1}  NumCallback = {(n)=> {setNum1(n)}}  CounterCallBack = {(n)=> {setCounter1(n)}}   Disabled = {false || Freeze}/>
<CounterTypeSelect Types = {TypeList} Valid = {TheValids[1]} TheValue = {Counter2}  NumCallback = {(n)=> {setNum2(n)}}  CounterCallBack = {(n)=> {setCounter2(n)}}   Disabled = {(Num1==0 || Counter1==-1) || Freeze} />
<CounterTypeSelect Types = {TypeList} Valid = {TheValids[2]} TheValue = {Counter3}  NumCallback = {(n)=> {setNum3(n)}}  CounterCallBack = {(n)=> {setCounter3(n)}}   Disabled = {(Num2==0 || Counter2==-1) || (Num1==0 || Counter1==-1) || Freeze} />
<CounterTypeSelect Types = {TypeList} Valid = {TheValids[3]} TheValue = {Counter4}  NumCallback = {(n)=> {setNum4(n)}}  CounterCallBack = {(n)=> {setCounter4(n)}}   Disabled = {(Num3==0 || Counter3==-1) || (Num2==0 || Counter2==-1) || (Num1==0 || Counter1==-1) || Freeze}/>


<div id="plcwrap">
<input
                        name="PLC"
                        type="checkbox"
                        id="idPLC"
                        checked = {PLC}
                        onChange={(e) => setPLC (e.target.checked)}

                      />
                      <label > PLC</label>
</div>

</div>
</Col>
<Col md={4}>
<div id = "MiscNumbers">
<div className="HeaderText">
   Numbers must be non-zero
   </div>
<FieldGroup
              id={"CameraNumber"}
              type={"number"}
              label={"Serial number of L camera"}
          
              value={CameraNumber.toString()}
          
              onChange=  {(e)=>{if(Freeze) return; setCameraNumber ( toInt (e.target.value))}}

              disabled = {true}

              
            />

<FieldGroup
              id={"PcId"}
              type={"number"}
              label={"Identidy number for PC"}
          
              value={PcId.toString()}
          
              onChange=  {(e)=>{if(Freeze) return;setPcId ( toInt (e.target.value))}}
              disabled = {Freeze}
              
            />
<FieldGroup
              id={"ProgramVer"}
              type={"number"}
              label={"Program version"}
          
              value={ProgramVer.toString()}
          
              onChange=   {(e)=>{if(Freeze) return;setProgramVer ( toInt (e.target.value))}}
              disabled = {Freeze}
              
            />
            </div>  
            </Col>

<Col md={4}>
   <Row>

 {TheCheckboxes}


            </Row>
            <div id="Comment">
Comment (optional):
            <textarea
        
  rows = {2}    // Specifies the number of visible text lines
  cols = {50}    // Specifies the width of the textarea in characters
  value = {Comment} //"Hello World"   // Specifies the initial value of the textarea
  placeholder = "Add your comment"   // Specifies a short hint that describes the expected value of the textarea
  wrap = "soft"   // Specifies how the text in the textarea should be wrapped
  //readOnly = {true}   // Specifies that the textarea is read-only, meaning the user cannot modify its content
  name = "comment"   // Specifies the name of the textarea, which can be used when submitting a form
  disabled = {false}   //  Specifies that the textarea is disabled, meaning the user cannot interact with it
  //minLength = {50}   // Specifies the minimum number of characters required in the textarea
  maxLength = {200}   // Specifies the maximum number of characters allowed in the textarea
  onChange = {(e)=>setComment (e.target.value)}
/>
</div>
            
            <Row>
               
<div id="GenButton">
   
            <Button variant="outline-secondary" type="Button" block   
            disabled = {GenDisabled||  Freeze}
             onClick={() =>
               fetchLicence(
                  Counter1, Counter2,Counter3,Counter4,
                  Num1, Num2, Num3, Num4,
                  ProgramVer, PcId, CameraNumber,
                  PLC, VakiCloud
               )
             }
            
            > Generate </Button>
            </div>

            <div id="GenResult">
    
                  {TheLink}  

               </div>


            </Row>
            <Row>
               <div id="DivMsg">
                  <label>
                     {TheMsg}
                     </label>
                  </div>
               </Row>
               <Row>
                  <div id="ClearForm">
                  <br/>
               <Button variant="outline-secondary" type="Button"    onClick = {onReset}   > Clear form </Button>
               </div>
               </Row>



 </Col>  

</Row>
 
<Row>
          <Col md={12}>
            {/* <button onClick={saveLicence}>Store licence</button> */}
            {Tables(
              licenceList.data.listLicences.items,
              6,
              handleRowSelect,
              false
            )}
          </Col>
        </Row>




 </div>


}

